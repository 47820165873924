import React from 'react';
import PropTypes from 'prop-types';

import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import cutDown from 'core/utils/cut-down';
import resolveRelationships from 'core/utils/relationships';

import TextGray from 'site/components/TextGray';

import { cardDateFormat } from 'site/utils';
import styles from './index.styl';

const requiredPayloadImports = ['image', 'search_result'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  search_result: {
    title: '',
    body: '',
  },
});

function CardSearch(props) {
  const {
    content,
    theme,
    isDesktop,
  } = props;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
  } = relationships(content);


  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      announce,
      topic_type: topicType,
      published_at: published,
    },
  } = content;

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  return (
    <Link to={link} type='secondary'>
      <style jsx>{`
        .${styles.headline}
          color ${theme.colors.primary}
          font bold 20px/1.2 ${theme.fonts.text}
          transition color ${theme.animations.hover}
          :global(a):hover &
          & :global(b)
            color ${theme.colors.active1}

        .${styles.image}:after
          transition opacity ${theme.animations.hover}

        .announce
          font 500 18px/1.5 ${theme.fonts.display}
          color ${theme.colors.primary}
          & :global(b)
            color ${theme.colors.active1}
            font-weight normal
      `}</style>
      <div className={styles.content}>
        {isDesktop && (
          <div className={styles.time}>
            <TextGray>{cardDateFormat(published)}</TextGray>
          </div>
        )}
        <div className={styles.image}>
          <Image
            src={cover}
            previewSrc={previewCover}
            width={isDesktop ? 270 : 100}
            aspectRatio={270 / 165}
            position='top'
          />
          {Icon && (
            <div className={styles.icon}>
              <Icon height={20} width={20} />
            </div>
          )}
        </div>
        <div className={styles.text}>
          <div className={styles.headline}>
            <MarkdownWrapper inline>
              {searchTitle || listHeadline || headline}
            </MarkdownWrapper>
          </div>
          {(announce || searchBody) && isDesktop && (
            <div className='announce'>
              <MarkdownWrapper inline>
                {searchBody || cutDown(announce)}
              </MarkdownWrapper>
            </div>
          )}
          {!isDesktop && (
            <div className={styles.time}>
              <TextGray>{cardDateFormat(published)}</TextGray>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

CardSearch.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

const Card = cardHOC(CardSearch);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
