import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import bindProps from 'core/components/bindProps';

import Logo from 'site/components/Logo';
import Burger from 'site/icons/burger.svg';
import Close from 'site/icons/close.svg';
import Video from 'site/icons/Video.svg';
import Photo from 'site/icons/Photo.svg';
import Social from 'site/icons/Social.svg';
import Comments from 'site/icons/Comments.svg';
import ArrowRightRound from 'site/icons/ArrowRightRound.svg';
import ArrowLeftRound from 'site/icons/ArrowLeftRound.svg';
import SubscribeLogo from 'site/icons/SubscribeLogo.svg';
import DefaultAvatar from 'site/icons/DefaultAvatar.svg';
import Card2 from 'site/cards/Card2';

import {
  VERTICAL,
  BLOCK_HORIZONTAL,
} from 'site/components/Indents';

import { MOBILE, DESKTOP } from 'core/components/breakpoint/values';

import notFoundImg from 'site/pages/images/404.png';
import jsErrorImg from 'site/pages/images/jsError.png';

import fonts from './fonts';

const Card2Type1 = bindProps({ type: 1 })(Card2);

export const logoIcon =  bindProps({ isFooter: true })(Logo);

const icons = {
  logo: Logo,
  burger: Burger,
  Close,
  Video,
  Photo,
  Social,
  Comments,
  arrowLeft: ArrowLeftRound,
  arrowRight: ArrowRightRound,
  DefaultAvatar,
};


const styleError = {
  backgroundImage: 'linear-gradient(to bottom, #FF5937, #FF0078)',
  '-webkit-background-clip': 'text',
  '-webkitTextFillColor': 'transparent',
};

const errorSubtitle = (
  <div>Такой страницы <br /> не существует </div>
);

const titleJs = (
  <div style={styleError}>Что-то пошло не так</div>
);

const title404 = (
  <div style={styleError}>Ой!</div>
);

export default function baseTheme(atoms) {
  const {
    colors,
  } = atoms;

  const link = {
    secondary: {
      idle: {
        color: colors.primary,
        decoration: 'none',
      },

      hover: {
        color: colors.active1,
        decoration: 'none',
      },

      active: {
        color: color(colors.active2).mix(color(colors.primary), 0.2).string(),
        decoration: 'none',
      },

      visited: {
        color: colors.primary,
        decoration: 'none',
      },
    },

    footer: {
      idle: {
        color: colors.link,
        decoration: 'none',
      },

      hover: {
        color: colors.link,
        decoration: 'none',
      },

      active: {
        color: color(colors.active2).mix(color(colors.link), 0.2).string(),
        decoration: 'none',
      },

      visited: {
        color: colors.link,
        decoration: 'none',
      },
    },
  };

  const texts = {
    title1: {
      font: `bold 40px/1.25 ${fonts.text}`,
    },
    title2: {
      font: `bold 32px/1.2 ${fonts.text}`,
    },
    title3: {
      font: `bold 24px/28px ${fonts.text}`,
    },
    title4: {
      font: `bold 22px/28px ${fonts.text}`,
    },
    title5: {
      font: `bold 18px/22px ${fonts.text}`,
    },
    title6: {
      font: `bold 16px/20px ${fonts.text}`,
    },

    caption4: {
      font: `bold 14px/18px ${fonts.display}`,
    },

    headline: {
      font: `bold 50px/1.12 ${fonts.display}`,
    },

    body: {
      font: `18px/1.5 ${fonts.text}`,
    },

    lead: {
      font: `700 22px/29px ${fonts.text}`,
      color: atoms.colors.primary,
      marginTop: '29px',
      letterSpacing: 'normal',
    },

    incut: {
      font: `italic 24px/1.2 ${fonts.text}`,
    },
  };

  const shapkaLink = {
    font: `bold 11px/1.2 ${fonts.text}`,
    textTransform: 'uppercase',
    letterSpacing: '1px',

    active: {
      color: colors.primary,
      decoration: 'none',
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 18px/27px ${fonts.text}`,
      },
      info: {
        font: `400 16px/24px ${fonts.text}`,
      },
      link: {
        borderBottom: 'none',
        color: atoms.colors.link,
        hover: {
          color: atoms.colors.link,
          borderBottom: `1px ${atoms.colors.link} solid`,
        },
      },
    },
  };

  const shapka = {
    link: {
      ...shapkaLink,
      padding: '5px 15px',
    },
    padding: '20px 50px',
    background: 'transparent',
    backgroundOpened: 'transparent',
    height: 130,
    shadowTransition: 'none',
    dropdown: {
      link: {
        ...shapkaLink,
        padding: '5px 15px',
      },
      marginTop: '0',
    },
    logo: {
      width: 240,
      height: 66,
    },

    labelNoAds: {
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'uppercase',
    },
  };

  const scooter = {
    border: `solid 1px ${colors.active1}`,
    social: {
      size: 40,
      iconSize: 16,
      borderRadius: 40,
      margin: '0 0 0 20px',
      idle: {
        color: colors.active1,
        background: 'transparent',
        border: `1px solid ${colors.active1}`,
      },
      hover: {
        color: colors.active1,
        background: 'transparent',
        border: `1px solid ${colors.active1}`,
      },
    },
    padding: '0 50px',
    logo: {
      Icon: logoIcon,
      width: 180,
      height: 49,
    },
    menuLink: {
      font: `bold 11px/30px ${fonts.text}`,
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
  };

  const mark = {
    background: '#D1F6D1',
    padding: '0.05em',
  };

  const topicContent = {
    widgetsMaxWidth: 640,
    tableOfContents: {
      maxWidth: '640px',
      margin: '0 auto 30px',
      title: texts.title3,
    },
    widgetsWithNegativeMobileIndent: {
      'smartTeaser': true,
    },
  };

  const topicTopline = {
    font: `14px/1 ${fonts.text}`,
    color: color(colors.primary).alpha(0.5).string(),
    rubric: {
      border: '1px solid #ff9001',
      borderRadius: '30px',
      padding: '5px 10px',
      color: color(colors.primary).alpha(0.5).string(),
      hover: {
        color: '#ff9001',
        background: 'transparent',
      },
    },
  };

  const button = {
    const: {
      sizes: {
        extraSmall: {
          font: `12px/1.2 ${fonts.text}`,
        },
        medium: {
          font: `18px/1.3 ${fonts.text}`,
          height: '44px',
          padding: '8px 20px',
        },
        extraLarge: {
          font: `bold 14px/14px ${fonts.text}`,
          height: '42px',
          padding: '12px 50px',
        },
      },
    },
    primary: {
      idle: {
        background: colors.active1,
      },

      hover: {
        background: (
          color(colors.active1)
            .mix(color(colors.primary), 0.1)
            .string()
        ),
      },

      active: {
        background: (
          color(colors.active1)
            .mix(color(colors.primary), 0.1)
            .string()
        ),
      },

      disabled: {
        background: color(colors.active1).alpha(0.7).string(),
      },
    },
    secondary: {
      idle: {
        color: colors.primary,
        background: 'none',
        border: `1px solid ${colors.active1}`,
      },
      hover: {
        color: colors.primary,
        background: 'none',
        border: `1px solid ${colors.placeholder}`,
      },
      active: {
        color: colors.primary,
        background: color(colors.active1).alpha(0.5).string(),
        border: `1px solid ${colors.active2}`,
      },
      disabled: {
        color: colors.hint,
        background: 'none',
        border: `1px solid ${colors.hint}`,
      },
    },

    tertiary: {
      idle: {
        color: colors.gallery,
        background: 'none',
        border: `1px solid ${colors.divider}`,
      },
      hover: {
        color: colors.gallery,
        background: 'none',
        border: `1px solid ${colors.placeholder}`,
      },
      active: {
        color: colors.gallery,
        background: 'none',
        border: `1px solid ${colors.hint}`,
      },
      disabled: {
        color: colors.gallery,
        background: 'none',
        border: `1px solid ${colors.hint}`,
      },
    },
  };

  const bouesque = {
    const: {
      size: 'medium',
    },
  };

  const input = {
    const: {
      radius: '2px',
      sizes: {
        extraSmall: {
          font: `12px/1.2 ${fonts.text}`,
        },
        medium: {
          font: `14px/1.3 ${fonts.text}`,
        },
      },
    },
    basic: {
      idle: {
        background: 'transparent',
        border: `1px solid ${colors.placeholder}`,
      },

      hover: {
        background: 'transparent',
        border: `1px solid ${colors.placeholder}`,
      },

      focused: {
        background: 'transparent',
        border: `1px solid ${colors.active1}`,
      },

      disabled: {
        background: colors.hint,
        border: `1px solid ${colors.placeholder}`,
      },

      error: {
        background: 'transparent',
        border: `1px solid ${colors.placeholder}`,
      },
    },
  };

  const drum = {
    link: {
      font: texts.title5.font,
    },
    arrow: {
      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
    },
  };

  const bullet = {
    const: {
      size: '10px',
    },

    basic: {
      idle: {
        background: colors.placeholder,
      },

      hover: {
        background: (
          color(colors.placeholder)
            .mix(color(colors.primary), 0.2)
            .string()
        ),
      },

      active: {
        background: colors.primary,
      },
    },
  };

  const gallery = {
    slide: {
      background: color(colors.gallery).alpha(0.1).string(),
    },
    icons: {
      background: color(colors.gallery).alpha(0.2).string(),
    },
  };

  const spammer = {
    Icon: SubscribeLogo,
    background: colors.spammerBackground,

    [MOBILE]: {
      formElements: {
        size: 'small',
      },
    },

    [DESKTOP]: {
      borderRadius: '4px',
    },
  };

  const vikontImage = {
    maxWidth: 840,
  };

  const vikontOpinion = {
    bodyFontStyle: 'italic',
    opinion: {
      maxWidth: '100%',
      width: '100%',
      padding: '0 0 2px 0',
    },
    job: {
      font: `400 14px/20px ${fonts.text}`,
    },
    name: {
      font: `600 18px/27px ${fonts.text}`,
      marginBottom: '2px',
    },
    authorImage: {
      size: 56,
      marginRight: '20px',
    },
    body: {
      marginBottom: '16px',
      paddingLeft: '18px',
    },
    bodyBefore: {
      width: '2px',
    },
  };

  const vikontIncut = {
    borderWidth: '0',
    padding: '0',
  };

  const commentsPreview = {
    headline: {
      font: `bold 12px/20px ${fonts.text}`,
      color: atoms.colors.primary,
      textTransform: 'uppercase',
    },
    name: {
      font: `bold 16px/20px ${fonts.text}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.text}`,
    },
    comment: {
      font: `normal 16px/20px ${fonts.text}`,
    },
  };

  const comments = {
    const: {
      color: colors.active1,
    },
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.active1,
        color: atoms.colors.primary,
        borderRadius: '10px',
        textTransform: 'none',
        border: 'none',
      },
    },
  };

  const layout = {
    sideColumnIndent: '40px',
    contentColumnMaxWidth: '840px',
    indents: {
      top: VERTICAL,
      right: BLOCK_HORIZONTAL,
      bottom: VERTICAL,
      left: BLOCK_HORIZONTAL,
    },
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `700 14px/20px ${fonts.text}`,
      topSpacing: VERTICAL,
      size: 'small',
    },
    socialMedia: {
      type: 'fancy',
    },
    links: {
      borderBottom: '1px transparent solid',
      font: `400 14px/21px ${fonts.text}`,
      title: {
        font: `400 14px/21px ${fonts.text}`,
      },
      hover: {
        borderBottom: `1px ${atoms.colors.link} solid`,
      },
    },
    block: {
      interitemSpacing: 0,
      spacing: VERTICAL,
    },
  };

  const topicTags = {
    font: `400 14px/21px ${fonts.text}`,
    color: atoms.colors.link,
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/pages/images/prime/macbookPreview-2x.png'),
      common: require('site/pages/images/prime/macbookPreview-1x.png'),
    },
  };

  const personal = {
    circleCheckMark: {
      stroke: '#000',
    },

    profileAvatar: {
      isCircular: true,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `700 48px/56px ${fonts.display}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `400 italic 35px/56px ${fonts.display}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.9).string(),
    },
    lead: { ...texts.lead },
    expert: {
      marginTop: '30px',
    },
  };

  const errorPage = {
    imgWidth: 260,
    title: {
      font: `bold 100px/120px ${fonts.text}`,
    },
    subtitle: {
      font: `bold 32px/36px ${fonts.text}`,
    },
    card: Card2Type1,
  };

  const pages = {
    error: {
      ...errorPage,
      imgUrl: jsErrorImg,
    },
    notFound: {
      ...errorPage,
      imgUrl: notFoundImg,
      title: {
        ...errorPage.title,
        name: title404,
      },
      subtitle: {
        ...errorPage.subtitle,
        name: errorSubtitle,
      },
    },
    sentryFallback: {
      ...errorPage,
      imgUrl: jsErrorImg,
      title: {
        ...errorPage.title,
        name: titleJs,
      },
      subtitle: {
        ...errorPage.subtitle,
        name: 'Мы уже знаем об этом и скоро всё исправим',
      },
      card: null,
    },
  };

  const vikontReadMore = {
    block: {
      background: '#F3F3F3',
      padding: '30px',
    },
    title: {
      color: color(atoms.colors.primary).alpha(0.5).string(),
      font: `400 14px/1.2 ${fonts.nuance}`,
      textTransform: 'none',
      letterSpacing: '0.08em',
      marginBottom: '20px',
    },
    drum: {
      arrowsShift: -15,
      arrowsShiftVertical: 90,
      itemWidth: '45%',
      spaceBetween: 20,
    },
  };

  const expert = {
    name: {
      idle: {
        font: `500 18px/27px ${fonts.display}`,
        margin: '0',
      },
      hover: {
        color: atoms.colors.active2,
        font: `500 18px/27px ${fonts.display}`,
        margin: '0',
      },
    },
    label: {
      background: atoms.colors.active2,
      letterSpacing: '0.02em',
      borderRadius: '8px',
    },
    avatar: {
      borderRadius: '8px',
      isAvatarCircular: true,
    },
  };

  const notes = {
    background: '#fff3ca',
  };

  const about = {
    headline: {
      font: texts.title1.font,
    },
    title2: {
      font: texts.title2.font,
    },
  };

  const cookiesBanner = {
    background: '#F0F0F0',
    text: {
      color: atoms.colors.primary,
    },
    button: {
      btnRadius: '30px',
      btnText: `600 14px/16px ${fonts.text}`,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    controls: {
      topicHeaderTexts,
      link,
      topicContent,
      button,
      shapka,
      input,
      scooter,
      topicFooter,
      topicTags,
      bouesque,
      drum,
      bullet,
      gallery,
      spammer,
      vikontImage,
      vikontOpinion,
      vikontReadMore,
      vikontIncut,
      commentsPreview,
      comments,
      author,
      personal,
      topicTopline,
      expert,
      notes,
      mark,
      smartTeaser,
      about,
      cookiesBanner,
    },
    layout,
    ads,
    colors: {
      adsColor: '#fff',
    },
    pages,
  }, atoms);
}
