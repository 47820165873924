import ContentBackground from 'core/components/ContentBackground';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Indent, SideIndent } from 'core/components/Wrappers';
import { Mobile, Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import CookiesBanner from 'core/components/CookiesBanner';

import Rubricator from 'site/components/Rubricator';

import Scooter from '../Scooter';
import Shapka from '../Shapka';
import { Billboard } from '../Ads/desktop';
import { TopBanner } from '../Ads/mobile';

export default function SiteLayout({ children }) {
  return (
    <StickyContainer>
      <ContentBackground>
        <Layout>
          <Header>
            <Shapka />
            <Desktop>
              <SideIndent>
                <Rubricator />
                <AdWrapper top={24}>
                  <Billboard />
                </AdWrapper>
                <Indent top={24} />
              </SideIndent>
            </Desktop>
            <Mobile>
              <TopBanner />
            </Mobile>
          </Header>
          <Content>
            {children}
          </Content>
          <Footer>
            <Scooter />
          </Footer>
          <CookiesBanner />
        </Layout>
      </ContentBackground>
    </StickyContainer>
  );
}
