import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Mjolnir from 'core/components/Mjolnir';
import bindProps from 'core/components/bindProps';
import withTabs from 'core/components/withTabs';
import { SimpleLoader } from 'core/components/Loader';

import Title5 from 'site/components/Title5';
import ThinButton from 'site/components/ThinButton';

import Card1, { Card1Type1, Card1Type2 } from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';

import { DOSUG_LIMIT } from 'site/constants';


const tabs = [
  {
    title: 'Игры',
    tag: 'igry',
    marginRight: '12px',
  }, {
    title: 'Книги',
    tag: 'knigi',
    marginRight: '12px',
  }, {
    title: 'Мультфильмы',
    tag: 'multfilmy',
  },
];

const before = (
  <div className='heading'>
    <style jsx>{`
      .heading
        :global(.mobile) &
          margin-bottom 15px
    `}</style>
    <Title5>Досуг ребенка</Title5>
  </div>
);

function MainDosug(props) {
  const {
    title,
    tag,
    isDesktop,
  } = props;

  const {
    data: content,
    isLoading,
    isError,
  } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      limit: DOSUG_LIMIT,
      fields: filterRequiredParams([Card1, Card2], 'fields'),
      include: filterRequiredParams([Card1, Card2], 'include'),
      tag,
      visibility: 'main_page',
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
      .catch(err => console.error(err)),
    select: denormalizeData,
  });

  if (isLoading) return <SimpleLoader />;
  if (isError) return null;

  return (
    <Mjolnir
      title={title}
      heroCard={bindProps({ imageVersion: 'main' })(isDesktop ? Card1Type1 : Card1Type2)}
      card={Card2}
      interitemSpacing={20}
      visibility={'main_page'}
      content={content}
    />
  );
}

MainDosug.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
  activeIndex: PropTypes.number,
  isDesktop: PropTypes.bool,
};

const mainDosugWithTabs = Component => function mainDosugComponent(props) {
  const TabbedComponent = withTabs({
    tabs,
    before,
    wrap: true,
    tabComponent: ThinButton,
    ...props.isDesktop && { beforeGap: 20 }, // eslint-disable-line react/prop-types
    ...!props.isDesktop && { beforeFullwidth: true }, // eslint-disable-line react/prop-types
  })(Component);

  return <TabbedComponent {...props} />;
};

export default mainDosugWithTabs(MainDosug);
